import { AccountToSort, AccountWithChildren, CreditLimitAccountToSort } from 'types/risk';

export function sortAccountsWithChildren(
  data: AccountToSort[] | CreditLimitAccountToSort[]
): AccountWithChildren[] {
  // Create a map for easy lookup by account
  const accountMap = new Map<string, AccountWithChildren>();

  // Add child accounts as a property to their parent
  const childrenMap = new Map<string, AccountWithChildren[]>();

  data.forEach((account) => {
    const enhancedAccount: AccountWithChildren = {
      ...account,
    };
    accountMap.set(account.account, enhancedAccount);
    if (account.parent_account) {
      if (!childrenMap.has(account.parent_account)) {
        childrenMap.set(account.parent_account, []);
      }
      childrenMap.get(account.parent_account)?.push(enhancedAccount);
    }
  });

  // Function to build the hierarchy recursively
  function buildHierarchy(account: any): AccountWithChildren {
    const children = childrenMap.get(account.account) || [];
    return {
      ...account,
      children: children.map((child) => {
        return buildHierarchy(child);
      }),
    };
  }

  // Process root accounts (accounts with no parent or parents not in the data)
  const rootAccounts = (data as AccountWithChildren[]).filter(
    (account) => !account.parent_account || !accountMap.has(account.parent_account)
  );

  // Build the hierarchy starting from root accounts
  return rootAccounts.sort((a, b) => a.account.localeCompare(b.account)).map(buildHierarchy);
}
