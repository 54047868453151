interface RowData<T> {
  id: string;
  data: T;
  children?: Record<string, RowData<T>>;
}

type Rows<T> = Record<string, RowData<T>>;

type RowUpdateData = {
  id: string;
  account: string;
  parent_account: string;
  symbol: string | null;
  maxnotional: number | null;
  maxqty: number | null;
  maxpercentaway: number | null;
  children: RowUpdateData[];
  assetClassType?: string;
  consumed?: number;
};

/**
 * Updates a row by adding its data.children to its children property recursively.
 */
export const updateRow = <T extends RowUpdateData>(
  rows: Rows<T>,
  targetId: string,
  type?: string
): Rows<T> => {
  const updatedRows = { ...rows };

  // Helper function to find a row by ID within the hierarchy
  function findRowById(currentRows: Rows<T>, id: string): RowData<T> | null {
    for (const row of Object.values(currentRows)) {
      if (row.id === id) return row;
      if (row.children) {
        const found = findRowById(row.children, id);
        if (found) return found;
      }
    }
    return null;
  }

  // Locate the target row
  const targetRow = findRowById(updatedRows, targetId);
  if (!targetRow) {
    console.error(`Row with ID ${targetId} not found.`);
    return rows;
  }

  // Add children to the target row only
  if (targetRow.data.children && targetRow.data.children.length > 0) {
    const childrenMap: Record<string, RowData<T>> = {};

    targetRow.data.children.forEach((child) => {
      childrenMap[child.id] = {
        id: child.id,
        data: type ? ({ ...child, assetClassType: type } as T) : ({ ...child } as T),
        children: {},
      };
    });

    targetRow.children = {
      ...targetRow.children,
      ...childrenMap,
    };
  }

  return updatedRows;
};

export const hasChildren = <T extends RowUpdateData>(rows: Rows<T>, targetId: string): boolean => {
  // Helper function to find a row by ID within the hierarchy
  function findRowById(currentRows: Rows<T>, id: string): RowData<T> | null {
    for (const row of Object.values(currentRows)) {
      if (row.id === id) return row;
      if (row.children) {
        const found = findRowById(row.children, id);
        if (found) return found;
      }
    }
    return null;
  }

  const targetRow = findRowById(rows, targetId);
  if (!targetRow) {
    console.error(`Row with ID ${targetId} not found.`);
    return false;
  }

  return targetRow.children && Object.keys(targetRow.children).length > 0;
};

export const deleteChildren = <T extends RowUpdateData>(
  rows: Rows<T>,
  targetId: string
): Rows<T> => {
  const updatedRows = { ...rows };

  // Helper function to find a row by ID within the hierarchy
  function findRowById(currentRows: Rows<T>, id: string): RowData<T> | null {
    for (const row of Object.values(currentRows)) {
      if (row.id === id) return row;
      if (row.children) {
        const found = findRowById(row.children, id);
        if (found) return found;
      }
    }
    return null;
  }

  // Locate the target row
  const targetRow = findRowById(updatedRows, targetId);
  if (!targetRow) {
    console.error(`Row with ID ${targetId} not found.`);
    return rows;
  }

  // Delete the children property
  if (targetRow.children) {
    delete targetRow.children;
  }

  return updatedRows;
};

export const deleteRow = <T extends RowUpdateData>(rows: Rows<T>, targetId: string): Rows<T> => {
  const updatedRows = { ...rows };

  // Helper function to delete a row by ID within the hierarchy
  function deleteRowById(currentRows: Rows<T>, id: string): boolean {
    for (const key of Object.keys(currentRows)) {
      if (key === id) {
        delete currentRows[key];
        return true;
      }
      if (currentRows[key].children) {
        const childrenDeleted = deleteRowById(currentRows[key].children, id);
        if (childrenDeleted) {
          return true;
        }
      }
      // Remove the targetId from data.children
      const dataChildren = currentRows[key].data.children;
      if (dataChildren) {
        currentRows[key].data.children = dataChildren.filter((child) => child.id !== id);
      }
    }
    return false;
  }

  // Delete the target row
  const rowDeleted = deleteRowById(updatedRows, targetId);
  if (!rowDeleted) {
    console.error(`Row with ID ${targetId} not found.`);
  }

  return updatedRows;
};

export const editRow = <T extends RowUpdateData>(
  rows: Rows<T>,
  targetId: string,
  newData: Omit<T, 'children'>
): Rows<T> => {
  const updatedRows = { ...rows };

  // Helper function to update a row by ID within the hierarchy
  function updateRowById(currentRows: Rows<T>, id: string): boolean {
    for (const key of Object.keys(currentRows)) {
      if (key === id) {
        // Update the row's data with the new data
        currentRows[key].data = { ...currentRows[key].data, ...newData };
        return true;
      }
      if (currentRows[key].children) {
        const childrenUpdated = updateRowById(currentRows[key].children, id);
        if (childrenUpdated) {
          return true;
        }
      }
    }
    return false;
  }

  // Update the target row
  const rowUpdated = updateRowById(updatedRows, targetId);
  if (!rowUpdated) {
    console.error(`Row with ID ${targetId} not found.`);
  }

  return updatedRows;
};

export const updateCreditLimitConsumed = <T extends RowUpdateData>(
  rows: Rows<T>,
  targetId: string,
  newConsumed: number
): Rows<T> => {
  const updatedRows = { ...rows };

  // Helper function to update the consumed value of the target account
  function updateConsumedById(currentRows: Rows<T>, id: string): boolean {
    for (const key of Object.keys(currentRows)) {
      if (key === id) {
        currentRows[key].data.consumed = newConsumed;
        return true;
      }
      if (currentRows[key].children) {
        const childrenUpdated = updateConsumedById(currentRows[key].children, id);
        if (childrenUpdated) {
          return true;
        }
      }
      // Update the consumed value in data.children
      const dataChildren = currentRows[key].data.children;
      if (dataChildren) {
        for (const child of dataChildren) {
          if (child.id === id) {
            child.consumed = newConsumed;
            return true;
          }
        }
      }
    }
    return false;
  }

  updateConsumedById(updatedRows, targetId);
  return updatedRows;
};
