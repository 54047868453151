import { CenteredModal } from 'components/CenteredModal';
import { QuestionModal } from 'components/QuestionModal';
import { Table, TableMode } from 'components/Table';
import { Column } from 'components/Table/types/column';
import { RowData } from 'components/Table/types/rowData';
import { CRUDActionsContext } from 'contexts/CRUDActionsContext';
import { useCRUD } from 'hooks/useCRUD';
import { noop } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAssetClassRequest,
  deleteSymbolLimitsRows,
  editSymbolLimitsRows,
  removeSymbolLimits,
  riskSelector,
  setSymbolLimitsRows,
  updateSymbolLimits,
} from 'redux/reducers/riskReducer';
import { columns } from 'routes/Risk/columns';
import { EditSymbolModal } from 'routes/Risk/components/EditSymbolModal';
import classes from 'routes/Risk/risk.module.scss';
import { sortAccountsWithChildren } from 'routes/Risk/utils';
import {
  Account,
  AccountToSort,
  AssetClassType,
  SymbolLimits,
  SymbolTableRow,
  UpdateOrCreateEntryRequest,
} from 'types/risk';

interface Props {
  readonly items: readonly Account[];
  readonly type: AssetClassType;
  readonly label: string;
}

interface SimpleFilter {
  readonly key: keyof SymbolTableRow;
  readonly value: string;
}

export const SymbolLimitsTable: React.FC<Props> = (props: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const { symbolLimitsRows } = useSelector(riskSelector);

  const [currentFilter, setCurrentFilter] = React.useState<SimpleFilter | null>(null);
  const { items = [], type } = props || {};
  const filteredColumn = React.useMemo((): Column<SymbolTableRow> | null => {
    if (currentFilter === null) {
      return null;
    }

    return columns.find(
      (column: Column<SymbolTableRow>): boolean => column.key === currentFilter.key
    );
  }, [currentFilter]);

  const data = items.reduce(
    (
      reduced: readonly SymbolTableRow[],
      { account, symbolLimits, parent_account }: Account
    ): readonly SymbolTableRow[] =>
      [
        ...reduced,
        ...symbolLimits.map(
          (symbolLimits: SymbolLimits): SymbolTableRow => ({
            id: account,
            account: account,
            parent_account: parent_account,
            ...symbolLimits,
          })
        ),
      ].filter((item: SymbolTableRow): boolean => {
        if (currentFilter === null || filteredColumn === null) {
          return true;
        }

        return filteredColumn.textuallyOverlap(item[currentFilter.key], currentFilter.value);
      }),
    []
  );

  const [crud, mode, selectedRow] = useCRUD<SymbolTableRow>(data, {} as SymbolTableRow);

  // Handles the edit operation
  const handleEdit = React.useCallback(
    (request: UpdateOrCreateEntryRequest): void => {
      // Step 1: Delete the existing entry (by `id`)
      dispatch(
        removeSymbolLimits(
          createAssetClassRequest(type, {
            account: selectedRow.account,
            symbol: selectedRow.symbol,
          })
        )
      );
      dispatch(
        editSymbolLimitsRows(
          createAssetClassRequest(type, {
            assetClassType: type,

            symbolLimits: {
              [request.account]: {
                data: { ...request.symbolLimits } as SymbolTableRow,
                id: request.account,
              },
            },
          })
        )
      );
      // Step 2: Submit the new entry with updated values
      dispatch(updateSymbolLimits(createAssetClassRequest(type, request)));
      crud.reset();
    },
    [crud, dispatch, selectedRow, type]
  );

  const handleRemove = React.useCallback((): void => {
    dispatch(
      removeSymbolLimits(
        createAssetClassRequest(type, {
          account: selectedRow.account,
          symbol: selectedRow.symbol,
        })
      )
    );
    dispatch(
      deleteSymbolLimitsRows(
        createAssetClassRequest(type, {
          assetClassType: type,
          symbolLimits: { [selectedRow.id]: { data: selectedRow, id: selectedRow.id } },
        })
      )
    );
    crud.reset();
  }, [crud, dispatch, selectedRow, type]);

  const mutableData: AccountToSort[] = data.map((item) => ({
    id: item.account,
    account: item.account,
    parent_account: item.parent_account,
    symbol: item.symbol,
    maxnotional: item.maxnotional,
    maxqty: item.maxqty,
    maxpercentaway: item.maxpercentaway,
  }));

  const initialSortedAccounts = sortAccountsWithChildren(mutableData) as AccountToSort[];

  const rows = React.useMemo(
    (): Record<string, RowData<SymbolTableRow>> =>
      Array.isArray(initialSortedAccounts)
        ? initialSortedAccounts.reduce(
            (
              rows: Record<string, RowData<SymbolTableRow>>,
              next: AccountToSort
            ): Record<string, RowData<SymbolTableRow>> => {
              return {
                ...rows,
                [next.id]: {
                  data: { ...next, assetClassType: type },
                  id: next.id,
                },
              };
            },
            {}
          )
        : {},
    [initialSortedAccounts, type]
  );

  useEffect(() => {
    dispatch(
      setSymbolLimitsRows(
        createAssetClassRequest(type, {
          assetClassType: type,
          symbolLimits: rows,
        })
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableGrow = React.useCallback(
    (item: SymbolTableRow): void => {
      const { account, id: _, ...symbolLimits } = item;

      dispatch(
        updateSymbolLimits(
          createAssetClassRequest(type, {
            account: account,
            assetClassType: type,
            symbolLimits: symbolLimits,
          })
        )
      );
    },
    [dispatch, type]
  );

  const handleFiltered = React.useCallback((key: keyof SymbolTableRow, value: string): void => {
    setCurrentFilter({ key, value });
  }, []);

  return (
    <CRUDActionsContext.Provider value={crud}>
      <div className={classes.tableContainer}>
        <Table
          rows={symbolLimitsRows[type] || {}}
          columns={columns}
          growable={false}
          onFiltered={handleFiltered}
          onGrow={handleTableGrow}
          onResetOriginalLayout={noop}
          virtualScroll={false}
        />
      </div>
      <CenteredModal open={mode === TableMode.edit} onClose={crud.reset}>
        <EditSymbolModal
          initialData={selectedRow}
          type={type}
          onClose={crud.reset}
          onSubmit={handleEdit}
        />
      </CenteredModal>
      <CenteredModal open={mode === TableMode.remove} onClose={crud.reset}>
        <QuestionModal
          title="Delete Entry"
          message="You are about to delete this entry, are you sure?"
          onYes={handleRemove}
          onNo={crud.reset}
        />
      </CenteredModal>
    </CRUDActionsContext.Provider>
  );
};
