import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { NumericInput } from 'routes/Risk/components/NumericInput';
import {
  CreditLimitsTableRow,
  emptyCreditLimitsTableRow,
  RiskCreditLimitsAccount,
} from 'types/risk';

interface Props {
  readonly initialData?: CreditLimitsTableRow;

  onClose(): void;
  onSubmit(request: RiskCreditLimitsAccount): void;
}

export const EditCreditLimitModal: React.FC<Props> = (props: Props): React.ReactElement => {
  const { initialData = emptyCreditLimitsTableRow, onClose, onSubmit } = props;
  const [form, setForm] = React.useState<RiskCreditLimitsAccount>(initialData);

  const handleCreate = React.useCallback((): void => {
    const payload = {
      account: form.account,
      buying_power: form.buying_power,
      consumed: form.consumed,
      parent_account: form.parent_account,
      rejection_level: form.rejection_level,
      threshold_level_first: form.threshold_level_first,
      threshold_level_second: form.threshold_level_second,
      threshold_level_third: form.threshold_level_third,
    };
    onSubmit(payload);
  }, [onSubmit, form]);

  const handleChange = React.useCallback(
    (name: keyof RiskCreditLimitsAccount, value: number | string | null): void => {
      setForm(
        (form: RiskCreditLimitsAccount): RiskCreditLimitsAccount => ({ ...form, [name]: value })
      );
    },
    []
  );

  return (
    <Grid padding={2} direction="column" gap={2} container>
      <Grid item>
        <NumericInput<RiskCreditLimitsAccount>
          name="buying_power"
          value={form.buying_power}
          label="Buying Power"
          onChange={handleChange}
        />
      </Grid>

      <Grid item>
        <NumericInput<RiskCreditLimitsAccount>
          name="consumed"
          value={form.consumed}
          label="Consumed"
          onChange={handleChange}
        />
      </Grid>

      <Grid item>
        <NumericInput<RiskCreditLimitsAccount>
          name="threshold_level_first"
          value={form.threshold_level_first}
          label="Warning Level 1"
          onChange={handleChange}
        />
      </Grid>

      <Grid item>
        <NumericInput
          name="threshold_level_second"
          value={form.threshold_level_second}
          label="Warning Level 2"
          onChange={handleChange}
        />
      </Grid>
      <Grid item>
        <NumericInput
          name="threshold_level_third"
          value={form.threshold_level_third}
          label="Warning Level 3"
          onChange={handleChange}
        />
      </Grid>
      <Grid item>
        <NumericInput
          name="rejection_level"
          value={form.rejection_level}
          label="Rejection Level"
          onChange={handleChange}
        />
      </Grid>

      <Grid display="flex" alignItems="center" justifyContent="end" marginTop={2} gap={2}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleCreate}>
          {initialData === emptyCreditLimitsTableRow ? 'Add' : 'Update'}
        </Button>
      </Grid>
    </Grid>
  );
};
