import { CRUDButtons } from 'components/CRUDButtons';
import { Alignment, Column, ColumnType } from 'components/Table/types/column';
import { NestedRow } from 'routes/Risk/components/HierarchyCell';
import { CreditLimitsTableRow } from 'types/risk';

export const creditLimitsColumns: Array<Column<CreditLimitsTableRow>> = [
  {
    minWidth: 48,
    weight: 0.0001,
    columnType: ColumnType.custom,
    key: 'id',
    filterable: false,
    header: '',
    textuallyOverlap(value: any, keyword: string): boolean {
      return value.includes(keyword);
    },
    sortingDifference(v1: any, v2: any): number {
      return v1.localeCompare(v2);
    },
    component: CRUDButtons,
  },
  {
    key: 'account',
    columnType: ColumnType.custom,
    header: 'Account',
    weight: 1,
    alignment: Alignment.left,
    editable: false,
    filterable: true,
    sortingDifference(v1: string, v2: string): number {
      return v1.localeCompare(v2);
    },
    textuallyOverlap(value: string, keyword: string): boolean {
      return value.includes(keyword);
    },
    component: (props) => <NestedRow account={props} limitType="credit" />,
  },
  {
    key: 'buying_power',
    columnType: ColumnType.integer,
    alignment: Alignment.center,
    header: 'Buying Power',
    editable: true,
    filterable: true,
    textuallyOverlap(value: any, keyword: string): boolean {
      return value.includes(keyword);
    },
    sortingDifference(v1: any, v2: any): number {
      return v1.localeCompare(v2);
    },
  },
  {
    key: 'consumed',
    columnType: ColumnType.integer,
    alignment: Alignment.center,
    header: 'Consumed',
    editable: true,
    textuallyOverlap(value: any, keyword: string): boolean {
      return String(value).includes(keyword);
    },
    sortingDifference(v1: any, v2: any): number {
      return Number(v1) - Number(v2);
    },
  },
  {
    key: 'threshold_level_first',
    columnType: ColumnType.integer,
    alignment: Alignment.center,
    header: 'Warning Level 1',
    editable: true,
    textuallyOverlap(value: any, keyword: string): boolean {
      return String(value).includes(keyword);
    },
    sortingDifference(v1: any, v2: any): number {
      return Number(v1) - Number(v2);
    },
  },
  {
    key: 'threshold_level_second',
    columnType: ColumnType.integer,
    alignment: Alignment.center,
    header: 'Warning Level 2',
    editable: true,
    textuallyOverlap(value: any, keyword: string): boolean {
      return String(value).includes(keyword);
    },
    sortingDifference(v1: any, v2: any): number {
      return Number(v1) - Number(v2);
    },
  },
  {
    key: 'threshold_level_third',
    columnType: ColumnType.integer,
    alignment: Alignment.center,
    header: 'Warning Level 3',
    editable: true,
    textuallyOverlap(value: any, keyword: string): boolean {
      return String(value).includes(keyword);
    },
    sortingDifference(v1: any, v2: any): number {
      return Number(v1) - Number(v2);
    },
  },
  {
    key: 'rejection_level',
    columnType: ColumnType.integer,
    alignment: Alignment.center,
    header: 'Rejection Level',
    editable: true,
    textuallyOverlap(value: any, keyword: string): boolean {
      return String(value).includes(keyword);
    },
    sortingDifference(v1: any, v2: any): number {
      return Number(v1) - Number(v2);
    },
  },
];
