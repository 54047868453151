import { RowData } from 'components/Table/types/rowData';
import { WithID } from 'types/identifiable';

type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export interface SymbolLimits {
  readonly symbol: string;
  readonly maxnotional: number;
  readonly maxqty: number;
  readonly maxpercentaway: number;
  readonly parent_account: string;
  readonly assetClassType?: AssetClassType;
  readonly children?: SymbolLimits[];
}

export interface Account {
  readonly account: string;
  readonly parent_account: string;
  readonly symbolLimits: readonly SymbolLimits[];
}

export interface AccountsGroup {
  readonly accounttag: number;
  readonly accounts: readonly Account[];
}

export interface RiskMatrix {
  readonly riskengineid: string;
  readonly sessionid: string;
  readonly equities: AccountsGroup;
  readonly options: AccountsGroup;
}

export interface NewAccount {
  readonly account: string;
  readonly parent_account: string;
  readonly company: string;
  readonly mpid: string;
  readonly imid: string;
  readonly clearingaccount: string;
  readonly contacts: string;
  readonly security_type: string;
}

export interface RiskAccount {
  readonly riskengineid: string | number;
  readonly accounts: NewAccount[];
}

export interface UpdateOrCreateEntryRequest {
  readonly account: string;
  readonly assetClassType: AssetClassType;
  readonly symbolLimits: SymbolLimits;
}

export interface RemoveSymbolLimitsRequest {
  readonly account: string;
  readonly symbol: string;
}

export type SymbolTableRow = WithID<SymbolLimits & { readonly account: string }>;

export const emptySymbolTableRow: Nullable<SymbolTableRow> = {
  id: '',
  account: '',
  symbol: null,
  maxnotional: null,
  maxqty: null,
  maxpercentaway: null,
  parent_account: null,
};

export type AssetClassType = keyof Pick<RiskMatrix, 'equities' | 'options'>;

export interface AccountToSort {
  id: string;
  account: string;
  parent_account: string | null;
  symbol: string | null;
  maxnotional: number | null;
  maxqty: number | null;
  maxpercentaway: number | null;
  childMargin?: string; // New property
  isParent?: boolean;
  accountCountIndex?: string;
  isExpanded?: boolean;
  children?: AccountToSort[];
}

export interface AccountToSortSymbolLimits {
  equities: Record<string, RowData<SymbolTableRow>>;
  options: Record<string, RowData<SymbolTableRow>>;
}

export interface RiskCreditLimitsAccount {
  readonly account: string;
  readonly parent_account: string;
  readonly buying_power: number;
  readonly consumed: number;
  readonly threshold_level_first: number;
  readonly threshold_level_second: number;
  readonly threshold_level_third: number;
  readonly rejection_level: number;
}

export interface RiskCreditLimitsMatrix {
  riskengineid: string;
  accounts: RiskCreditLimitsAccount[];
}

export interface CreditLimits {
  readonly buying_power: number;
  readonly consumed: number;
  readonly threshold_level_first: number;
  readonly threshold_level_second: number;
  readonly threshold_level_third: number;
  readonly rejection_level: number;
  readonly account: string;
  readonly parent_account: string;
  readonly children?: CreditLimits[];
}

export type CreditLimitsTableRow = WithID<CreditLimits & { readonly account: string }>;

export const emptyCreditLimitsTableRow: Nullable<CreditLimitsTableRow> = {
  id: '',
  account: '',
  parent_account: '',
  buying_power: null,
  consumed: null,
  threshold_level_first: null,
  threshold_level_second: null,
  threshold_level_third: null,
  rejection_level: null,
};

export interface CreditLimitAccountToSort {
  id: string;
  account: string;
  parent_account: string | null;
  buying_power: number;
  consumed: number;
  threshold_level_first: number;
  threshold_level_second: number;
  threshold_level_third: number;
  rejection_level: number;
  childMargin?: string;
  isParent?: boolean;
  accountCountIndex?: string;
}

export type AccountWithChildren =
  | (AccountToSort & {
      children?: AccountWithChildren[];
      isParent?: boolean;
      isExpanded?: boolean;
    })
  | (CreditLimitAccountToSort & {
      children?: AccountWithChildren[];
      isParent?: boolean;
      isExpanded?: boolean;
    });

export interface SetAccountWithChildren {
  readonly assetClassType: AssetClassType;
  readonly symbolLimits: Record<string, RowData<SymbolTableRow>>;
}

export type AccountToSortCreditLimits = Record<string, RowData<CreditLimitsTableRow>>;
