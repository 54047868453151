import { AbortSignal } from '@microsoft/signalr';
import { Api } from 'api';
import { isCRUDResponse } from 'api/interfaces/CRUDResponse';
import { AnyAction } from 'redux';
import {
  creatingRiskAccountCompleted,
  creatingRiskAccountFailed,
  creatingRiskAccountStarted,
  creatingRiskAccountSucceeded,
  fetchingRiskAccountsCompleted,
  fetchingRiskAccountsStarted,
  fetchingRiskCreditLimitsMatrixCompleted,
  fetchingRiskCreditLimitsMatrixStarted,
  fetchingRiskEnginesCompleted,
  fetchingRiskEnginesStarted,
  fetchingRiskMatrixCompleted,
  fetchingRiskMatrixStarted,
  fetchingRiskSessionsCompleted,
  fetchingRiskSessionsStarted,
  savingRiskCreditLimitsMatrixCompleted,
  savingRiskCreditLimitsMatrixFailed,
  savingRiskCreditLimitsMatrixStarted,
  savingRiskCreditLimitsMatrixSucceeded,
  savingRiskMatrixCompleted,
  savingRiskMatrixFailed,
  savingRiskMatrixStarted,
  savingRiskMatrixSucceeded,
  setRiskAccounts,
  setRiskCreditLimitsMatrix,
  setRiskEngines,
  setRiskMatrix,
  setRiskSessions,
} from 'redux/reducers/riskReducer';
import { RiskAccount, RiskCreditLimitsMatrix, RiskMatrix } from 'types/risk';

export async function* fetchRiskMatrixService(
  api: Api,
  signal: AbortSignal,
  sessionID: string,
  engineID: string
): AsyncGenerator<AnyAction> {
  void signal;
  yield fetchingRiskMatrixStarted();

  try {
    const task = api.getRiskMatrix(sessionID, engineID);
    const response = await task.run();

    const matrix: RiskMatrix =
      response !== null && !isCRUDResponse(response)
        ? response
        : {
            riskengineid: engineID,
            sessionid: sessionID,
            options: {
              accounts: [],
              accounttag: 1,
            },
            equities: {
              accounts: [],
              accounttag: 1,
            },
          };

    yield setRiskMatrix({
      ...matrix,
      riskengineid: engineID,
      options: {
        ...matrix.options,
        accounts: matrix.options.accounts ?? [],
      },
      equities: {
        ...matrix.equities,
        accounts: matrix.equities.accounts ?? [],
      },
    });
  } catch (error: any) {
    console.warn(error);
  } finally {
    yield fetchingRiskMatrixCompleted();
  }
}

export async function* updateRiskMatrixService(
  api: Api,
  signal: AbortSignal,
  matrix: RiskMatrix
): AsyncGenerator<AnyAction> {
  void signal;

  yield savingRiskMatrixStarted();
  try {
    const task = api.updateRiskMatrix(matrix);
    const response = await task.run();

    if (!response.success) {
      yield savingRiskMatrixFailed(response.message);
    } else {
      yield savingRiskMatrixSucceeded();
    }
  } catch (error: any) {
    console.warn(error);
  } finally {
    yield savingRiskMatrixCompleted();
  }
}

export async function* fetchRiskSessionsService(
  api: Api,
  signal: AbortSignal,
  riskEngineID: string
): AsyncGenerator<AnyAction> {
  void signal;

  yield fetchingRiskSessionsStarted();
  try {
    const task = api.getRiskSessions(riskEngineID);
    const response = await task.run();
    console.log(response);
    const task2 = api.getRiskMatrix(response[0].sessionid, riskEngineID);
    const response2 = await task2.run();
    const matrix: RiskMatrix =
      response2 !== null && !isCRUDResponse(response2)
        ? response2
        : {
            riskengineid: riskEngineID,
            sessionid: response[0].sessionid,
            options: {
              accounts: [],
              accounttag: 1,
            },
            equities: {
              accounts: [],
              accounttag: 1,
            },
          };
    yield setRiskSessions(response);
    yield setRiskMatrix({
      ...matrix,
      riskengineid: riskEngineID,
      options: {
        ...matrix.options,
        accounts: matrix.options.accounts ?? [],
      },
      equities: {
        ...matrix.equities,
        accounts: matrix.equities.accounts ?? [],
      },
    });
  } catch (error: any) {
    console.warn(error);
  } finally {
    yield fetchingRiskSessionsCompleted();
  }
}

export async function* fetchRiskEnginesService(
  api: Api,
  signal: AbortSignal
): AsyncGenerator<AnyAction> {
  void signal;

  yield fetchingRiskEnginesStarted();
  try {
    const task = api.getRiskEngines();
    const response = await task.run();

    yield setRiskEngines(response);
  } catch (error: any) {
    console.warn(error);
  } finally {
    yield fetchingRiskEnginesCompleted();
  }
}

export async function* createRiskAccountService(
  api: Api,
  signal: AbortSignal,
  account: RiskAccount
): AsyncGenerator<AnyAction> {
  void signal;

  yield creatingRiskAccountStarted();
  try {
    const task = api.createRiskAccount(account);
    const response = await task.run();

    if (!response.success) {
      yield creatingRiskAccountFailed(response.message);
    } else {
      yield creatingRiskAccountSucceeded();
    }
  } catch (error: any) {
    console.warn(error);
  } finally {
    yield creatingRiskAccountCompleted();
  }
}

export async function* fetchRiskAccountsService(
  api: Api,
  signal: AbortSignal,
  engineID: string
): AsyncGenerator<AnyAction> {
  void signal;
  yield fetchingRiskAccountsStarted();

  try {
    const task = api.getRiskAccounts(engineID);
    const response = await task.run();

    yield setRiskAccounts(response as RiskAccount);
  } catch (error: any) {
    console.warn(error);
  } finally {
    yield fetchingRiskAccountsCompleted();
  }
}

export async function* fetchRiskCreditLimitsMatrixService(
  api: Api,
  signal: AbortSignal,
  engineID: string
): AsyncGenerator<AnyAction> {
  void signal;
  yield fetchingRiskCreditLimitsMatrixStarted();

  try {
    const task = api.getRiskCreditLimitsMatrix(engineID);
    const response = await task.run();
    yield setRiskCreditLimitsMatrix(response as RiskCreditLimitsMatrix);
  } catch (error: any) {
    console.warn(error);
  } finally {
    yield fetchingRiskCreditLimitsMatrixCompleted();
  }
}

export async function* updateRiskCreditLimitsMatrixService(
  api: Api,
  signal: AbortSignal,
  matrix: RiskCreditLimitsMatrix
): AsyncGenerator<AnyAction> {
  void signal;

  yield savingRiskCreditLimitsMatrixStarted();
  try {
    const task = api.updateRiskCreditLimitsMatrix(matrix);
    const response = await task.run();

    if (!response.success) {
      yield savingRiskCreditLimitsMatrixFailed(response.message);
    } else {
      yield savingRiskCreditLimitsMatrixSucceeded();
    }
  } catch (error: any) {
    console.warn(error);
  } finally {
    yield savingRiskCreditLimitsMatrixCompleted();
  }
}
