import { CenteredModal } from 'components/CenteredModal';
import { QuestionModal } from 'components/QuestionModal';
import { Table, TableMode } from 'components/Table';
import { Column } from 'components/Table/types/column';
import { RowData } from 'components/Table/types/rowData';
import { CRUDActionsContext } from 'contexts/CRUDActionsContext';
import { useCRUD } from 'hooks/useCRUD';
import { noop } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteCreditLimitsRows,
  editCreditLimitsRows,
  removeCreditLimits,
  riskSelector,
  setCreditLimitsRows,
  updateCreditLimits,
  updateCreditLimitsSignalR,
} from 'redux/reducers/riskReducer';
import { EditCreditLimitModal } from 'routes/Risk/components/EditCreditLimitModal';
import { creditLimitsColumns } from 'routes/Risk/creditLimitsColumns';
import classes from 'routes/Risk/risk.module.scss';
import { sortAccountsWithChildren } from 'routes/Risk/utils';
import { SignalR, SignalRContext } from 'signalR';
import {
  CreditLimitAccountToSort,
  CreditLimitsTableRow,
  RiskCreditLimitsAccount,
} from 'types/risk';

interface Props {
  readonly items: readonly RiskCreditLimitsAccount[];
  readonly label: string;
}

interface SimpleFilter {
  readonly key: keyof CreditLimitsTableRow;
  readonly value: string;
}

export const CreditLimitsTable: React.FC<Props> = (props: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const { creditLimitsRows } = useSelector(riskSelector);

  const [currentFilter, setCurrentFilter] = React.useState<SimpleFilter | null>(null);
  const { items = [] } = props || {};
  const filteredColumn = React.useMemo((): Column<CreditLimitsTableRow> | null => {
    if (currentFilter === null) {
      return null;
    }

    return creditLimitsColumns.find(
      (column: Column<CreditLimitsTableRow>): boolean => column.key === currentFilter.key
    );
  }, [currentFilter]);

  const data: readonly CreditLimitsTableRow[] = items
    .map((item) => {
      return {
        ...item,
        id: item.account,
      };
    })
    .filter((item: CreditLimitsTableRow): boolean => {
      if (currentFilter === null || filteredColumn === null) {
        return true;
      }

      return filteredColumn.textuallyOverlap(item[currentFilter.key], currentFilter.value);
    });

  const [crud, mode, selectedRow] = useCRUD<CreditLimitsTableRow>(data, {} as CreditLimitsTableRow);

  //Handles the edit operation
  const handleEdit = React.useCallback(
    (request: RiskCreditLimitsAccount): void => {
      // Step 1: Delete the existing entry (by `id`)
      dispatch(removeCreditLimits(request.account));

      dispatch(
        editCreditLimitsRows({
          [request.account]: { data: { ...request, id: request.account }, id: request.account },
        })
      );

      // Step 2: Submit the new entry with updated values
      dispatch(updateCreditLimits(request));
      crud.reset();
    },
    [crud, dispatch]
  );

  const handleRemove = React.useCallback((): void => {
    dispatch(removeCreditLimits(selectedRow.account));
    dispatch(
      deleteCreditLimitsRows({
        [selectedRow.account]: { data: selectedRow, id: selectedRow.account },
      })
    );
    crud.reset();
  }, [dispatch, selectedRow, crud]);

  const mutableData: CreditLimitAccountToSort[] = data.map((item) => {
    return {
      ...item,
    };
  });

  const sortedAccounts = sortAccountsWithChildren(mutableData) as CreditLimitAccountToSort[];

  const rows = React.useMemo(
    (): Record<string, RowData<CreditLimitsTableRow>> =>
      sortedAccounts.reduce(
        (
          rows: Record<string, RowData<CreditLimitsTableRow>>,
          next: CreditLimitAccountToSort
        ): Record<string, RowData<CreditLimitsTableRow>> => {
          return {
            ...rows,
            [next.id]: { data: next, id: next.id },
          };
        },
        {}
      ),
    [sortedAccounts]
  );

  useEffect(() => {
    dispatch(setCreditLimitsRows(rows));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableGrow = React.useCallback(
    (item: CreditLimitsTableRow): void => {
      dispatch(updateCreditLimits(item));
    },
    [dispatch]
  );

  const handleFiltered = React.useCallback(
    (key: keyof CreditLimitsTableRow, value: string): void => {
      setCurrentFilter({ key, value });
    },
    []
  );
  const signalR = React.useContext<SignalR>(SignalRContext);
  const onRowMount = React.useCallback(
    (id: string, mounted: boolean): void => {
      if (mounted) {
        signalR.subscribeForRiskUpdate(id, (data: any): void => {
          dispatch(
            updateCreditLimitsSignalR({
              account: data.account,
              consumed: data.consumed,
            })
          );
        });
      } else {
        signalR.unsubscribeForRiskUpdate(id);
      }
    },
    [dispatch, signalR]
  );

  return (
    <CRUDActionsContext.Provider value={crud}>
      <div className={classes.tableContainer}>
        <Table
          rows={creditLimitsRows || {}}
          columns={creditLimitsColumns}
          growable={true}
          onFiltered={handleFiltered}
          onGrow={handleTableGrow}
          onResetOriginalLayout={noop}
          onRowMount={onRowMount}
        />
      </div>
      <CenteredModal open={mode === TableMode.edit} onClose={crud.reset}>
        <EditCreditLimitModal
          initialData={selectedRow}
          onClose={crud.reset}
          onSubmit={handleEdit}
        />
      </CenteredModal>
      <CenteredModal open={mode === TableMode.remove} onClose={crud.reset}>
        <QuestionModal
          title="Delete Entry"
          message="You are about to delete this entry, are you sure?"
          onYes={handleRemove}
          onNo={crud.reset}
        />
      </CenteredModal>
    </CRUDActionsContext.Provider>
  );
};
