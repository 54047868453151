import { Button } from '@mui/material';
import { Box } from '@mui/system';
import deepEqual from 'fast-deep-equal';
import { toClassName } from 'helpers/toClassName';
import React, { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { updateRiskMatrixAction } from 'redux/actions/riskActions';
import { AsyncAction } from 'redux/asyncAction';
import {
  restoreOriginalRiskMatrix,
  restoreOriginalSymbolLimitsRows,
  riskSelector,
} from 'redux/reducers/riskReducer';
import { AssetClassView } from 'routes/Risk/components/AssetClassView';
import classes from 'routes/Risk/risk.module.scss';
import { RiskMatrix } from 'types/risk';

interface Props {
  readonly matrix: RiskMatrix | null;
}

export const Matrix: React.FC<Props> = (props: Props): React.ReactElement => {
  const risk = useSelector(riskSelector);
  const dispatch = useDispatch<Dispatch<AsyncAction | AnyAction>>();

  const { matrix } = props;
  const { options, equities } = matrix ?? {};
  const modified = React.useMemo(
    (): boolean => risk.matrix !== risk.originalMatrix,
    [risk.matrix, risk.originalMatrix]
  );

  const disableSubmit = React.useMemo((): boolean => {
    return risk.savingRiskMatrix || deepEqual(risk.matrix, risk.originalMatrix);
  }, [risk.matrix, risk.originalMatrix, risk.savingRiskMatrix]);

  const handleReset = React.useCallback((): void => {
    dispatch(restoreOriginalRiskMatrix());
    dispatch(restoreOriginalSymbolLimitsRows());
  }, [dispatch]);

  const handleSubmit = React.useCallback((): void => {
    dispatch(updateRiskMatrixAction(risk.matrix));
  }, [dispatch, risk.matrix]);

  if (matrix === null) {
    return <div className={classes.matrix} />;
  } else {
    return (
      <div
        className={toClassName(classes.matrix, {
          [classes.savingMatrix]: risk.savingRiskMatrix,
        })}
      >
        <AssetClassView label="Options" group={options} type="options" />
        <AssetClassView label="Equities" group={equities} type="equities" />
        <Box className={classes.buttonsContainer}>
          <Button onClick={handleReset} disabled={!modified}>
            Reset
          </Button>
          <Button variant="contained" onClick={handleSubmit} disabled={disableSubmit}>
            Submit
          </Button>
        </Box>
      </div>
    );
  }
};
