import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Dispatch } from '@reduxjs/toolkit';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { createRiskAccountAction } from 'redux/actions/riskActions';
import { AsyncAction } from 'redux/asyncAction';
import { riskSelector } from 'redux/reducers/riskReducer';

const INITIAL_FORM_STATE = {
  headAccount: '',
  account: '',
  companyName: '',
  contacts: '',
  clearingAccount: '',
  MPID: '',
  IMID: '',
  securityType: '',
};

const RequiredIndicator = ({ required }: { required: boolean }) => {
  if (!required) return null;
  return (
    <span style={{ color: 'red', marginLeft: 4 }} aria-hidden="true">
      *
    </span>
  );
};

export const AccountForm = (): JSX.Element => {
  const dispatch = useDispatch<Dispatch<AsyncAction | AnyAction>>();
  const risk = useSelector(riskSelector);
  const { clientID, savingRiskAccount, riskAccounts } = risk;

  const [formState, setFormState] = useState<Record<string, string>>(INITIAL_FORM_STATE);
  const [includeOptions, setIncludeOptions] = useState(false);
  const [includeEquities, setIncludeEquities] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const accountToEdit = riskAccounts.accounts.find((account) => account.account === value);
    setFormState({
      ...accountToEdit,
      companyName: accountToEdit.company,
      clearingAccount: accountToEdit.clearingaccount,
      headAccount: accountToEdit.parent_account,
      MPID: accountToEdit.mpid,
      IMID: accountToEdit.imid,
    });
    if (accountToEdit.security_type === 'OPT') {
      setIncludeOptions(true);
      setIncludeEquities(false);
    }
    if (accountToEdit.security_type === 'EQ') {
      setIncludeOptions(false);
      setIncludeEquities(true);
    }
    if (accountToEdit.security_type === 'BOTH') {
      setIncludeOptions(true);
      setIncludeEquities(true);
    }
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIsEditing(e.target.checked);
    setFormState(INITIAL_FORM_STATE);
    setIncludeOptions(false);
    setIncludeEquities(false);
  };

  const handleCheckboxChange = (name: string, value: boolean) => {
    const newIncludeOptions = name === 'includeOptions' ? value : includeOptions;
    const newIncludeEquities = name === 'includeEquities' ? value : includeEquities;

    setIncludeOptions(newIncludeOptions);
    setIncludeEquities(newIncludeEquities);

    const securityType =
      newIncludeOptions && newIncludeEquities
        ? 'BOTH'
        : newIncludeOptions
        ? 'OPT'
        : newIncludeEquities
        ? 'EQ'
        : '';

    setFormState((prev) => ({ ...prev, securityType }));
  };

  const onSubmit = () => {
    const payload = {
      riskengineid: clientID,
      accounts: [
        {
          account: formState.account,
          parent_account: formState.headAccount,
          company: formState.companyName,
          mpid: formState.MPID,
          imid: formState.IMID,
          clearingaccount: formState.clearingAccount,
          contacts: formState.contacts,
          security_type: formState.securityType,
        },
      ],
    };

    dispatch(createRiskAccountAction(payload));

    setFormState(INITIAL_FORM_STATE);
    setIncludeOptions(false);
    setIncludeEquities(false);
  };

  const handleCancel = () => {
    setFormState(INITIAL_FORM_STATE);
    setIncludeOptions(false);
    setIncludeEquities(false);
  };

  return (
    <Box sx={{ p: 4 }}>
      <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 2 }}>
        <Typography variant="h5" gutterBottom>
          Account Form
        </Typography>

        <FormControlLabel
          control={<Switch checked={isEditing} onChange={handleSwitchChange} />}
          label="Edit account"
        />
      </Stack>
      <form onSubmit={(e) => e.preventDefault()}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="caption" gutterBottom>
              Account <RequiredIndicator required={true} />
            </Typography>
            {isEditing ? (
              <TextField
                select
                fullWidth
                name="account"
                id="account"
                value={formState.account || ''}
                onChange={handleSelectChange}
                placeholder="Select account"
                required
                SelectProps={{
                  native: true,
                }}
              >
                <option value="" disabled>
                  Select account
                </option>
                {riskAccounts.accounts.map((account) => (
                  <option key={account.account} value={account.account}>
                    {account.account}
                  </option>
                ))}
              </TextField>
            ) : (
              <TextField
                fullWidth
                name="account"
                id="account"
                value={formState.account || ''}
                onChange={onChange}
                placeholder="Enter account"
                required
              />
            )}

            <Typography variant="caption" gutterBottom>
              Head Account <RequiredIndicator required={true} />
            </Typography>
            <TextField
              fullWidth
              name="headAccount"
              id="headAccount"
              value={formState.headAccount || ''}
              onChange={onChange}
              placeholder="Enter head account"
              required
            />

            <Typography variant="caption" gutterBottom>
              Company Name <RequiredIndicator required={false} />
            </Typography>
            <TextField
              fullWidth
              name="companyName"
              id="companyName"
              value={formState.companyName || ''}
              onChange={onChange}
              placeholder="Enter company name"
            />

            <Typography variant="caption" gutterBottom>
              Contacts <RequiredIndicator required={false} />
            </Typography>
            <TextField
              fullWidth
              name="contacts"
              id="contacts"
              value={formState.contacts || ''}
              onChange={onChange}
              placeholder="Enter contacts"
            />

            <Typography variant="caption" gutterBottom>
              Clearing Account <RequiredIndicator required={false} />
            </Typography>
            <TextField
              fullWidth
              name="clearingAccount"
              id="clearingAccount"
              value={formState.clearingAccount || ''}
              onChange={onChange}
              placeholder="Enter clearing account"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="caption" gutterBottom>
              MPID <RequiredIndicator required={false} />
            </Typography>
            <TextField
              fullWidth
              name="MPID"
              id="MPID"
              value={formState.MPID || ''}
              onChange={onChange}
              placeholder="Enter MPID"
            />

            <Typography variant="caption" gutterBottom>
              IMID <RequiredIndicator required={false} />
            </Typography>
            <TextField
              fullWidth
              name="IMID"
              id="IMID"
              value={formState.IMID || ''}
              onChange={onChange}
              placeholder="Enter IMID"
            />

            <FormGroup sx={{ m: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="includeOptions"
                    checked={includeOptions}
                    onChange={(e) => handleCheckboxChange(e.target.name, e.target.checked)}
                  />
                }
                label={
                  <Typography variant="subtitle1">
                    Include the account in options
                    <RequiredIndicator required={true} />
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="includeEquities"
                    checked={includeEquities}
                    onChange={(e) => handleCheckboxChange(e.target.name, e.target.checked)}
                  />
                }
                label={
                  <Typography variant="subtitle1">
                    Include the account in equities
                    <RequiredIndicator required={true} />
                  </Typography>
                }
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                mt: 2,
              }}
            >
              <Button variant="outlined" color="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={
                  savingRiskAccount ||
                  !formState.account ||
                  !formState.headAccount ||
                  !formState.securityType
                }
                color="primary"
                onClick={onSubmit}
              >
                {savingRiskAccount ? <CircularProgress size={24} /> : 'Save'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
