import { Dispatch } from '@reduxjs/toolkit';
import { CenteredModal } from 'components/CenteredModal';
import { ErrorModal } from 'components/ErrorModal';
import { Scaffold } from 'components/Scaffold';
import { SuccessModal } from 'components/SuccessModal';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import {
  fetchRiskAccountsAction,
  fetchRiskCredirLimitsMatrixAction,
  fetchRiskEnginesAction,
  fetchRiskMatrixAction,
} from 'redux/actions/riskActions';
import { AsyncAction } from 'redux/asyncAction';
import { resetErrorMessage, resetSuccessMessage, riskSelector } from 'redux/reducers/riskReducer';
import { MatrixTabs } from 'routes/Risk/components/MatrixTabs';
import { RiskHeader } from 'routes/Risk/components/RiskHeader';

export const Risk: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<Dispatch<AsyncAction | AnyAction>>();
  const risk = useSelector(riskSelector);

  const closeSuccessModal = React.useCallback((): void => {
    dispatch(resetSuccessMessage());
  }, [dispatch]);

  const closeErrorModal = React.useCallback((): void => {
    dispatch(resetErrorMessage());
  }, [dispatch]);

  React.useEffect((): void => {
    if (risk.savingRiskAccount) return;

    dispatch(fetchRiskMatrixAction(risk.sessionID, risk.clientID));
  }, [dispatch, risk.clientID, risk.sessionID, risk.savingRiskAccount]);

  React.useEffect((): void => {
    dispatch(fetchRiskEnginesAction());
  }, [dispatch]);

  React.useEffect((): void => {
    if (risk.savingRiskAccount) return;
    dispatch(fetchRiskAccountsAction(risk.clientID));
  }, [dispatch, risk.clientID, risk.savingRiskAccount]);

  React.useEffect((): void => {
    if (risk.savingRiskAccount) return;
    dispatch(fetchRiskCredirLimitsMatrixAction(risk.clientID));
  }, [dispatch, risk.clientID, risk.savingRiskAccount]);

  return (
    <Scaffold title="Risk">
      <RiskHeader disabled={risk.savingRiskMatrix} />
      {risk.clientID && (
        <>
          <MatrixTabs />
        </>
      )}
      <CenteredModal open={!!risk.errorMessage} onClose={closeErrorModal}>
        <ErrorModal
          title="There was an error"
          message="An error occurred when executing your last operation"
          error={risk.errorMessage}
          onClose={closeErrorModal}
        />
      </CenteredModal>
      <CenteredModal open={!!risk.successMessage} onClose={closeSuccessModal}>
        <SuccessModal
          title="Operation Completed"
          message={risk.successMessage}
          onClose={closeSuccessModal}
        />
      </CenteredModal>
    </Scaffold>
  );
};
