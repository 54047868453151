import { AsyncAction, createAsyncAction } from 'redux/asyncAction';
import {
  createRiskAccountService,
  fetchRiskAccountsService,
  fetchRiskCreditLimitsMatrixService,
  fetchRiskEnginesService,
  fetchRiskMatrixService,
  fetchRiskSessionsService,
  updateRiskCreditLimitsMatrixService,
  updateRiskMatrixService,
} from 'redux/services/riskServices';
import { RiskAccount, RiskCreditLimitsMatrix, RiskMatrix } from 'types/risk';

export const fetchRiskMatrixAction = (sessionID: string, engineID: string): AsyncAction =>
  createAsyncAction(fetchRiskMatrixService, sessionID, engineID);

export const updateRiskMatrixAction = (matrix: RiskMatrix): AsyncAction =>
  createAsyncAction(updateRiskMatrixService, matrix);

export const fetchRiskSessionsAction = (riskEngineID: string): AsyncAction =>
  createAsyncAction(fetchRiskSessionsService, riskEngineID);

export const fetchRiskEnginesAction = (): AsyncAction => createAsyncAction(fetchRiskEnginesService);

export const createRiskAccountAction = (account: RiskAccount): AsyncAction =>
  createAsyncAction(createRiskAccountService, account);

export const fetchRiskAccountsAction = (engineID: string): AsyncAction =>
  createAsyncAction(fetchRiskAccountsService, engineID);

export const fetchRiskCredirLimitsMatrixAction = (engineID: string): AsyncAction =>
  createAsyncAction(fetchRiskCreditLimitsMatrixService, engineID);

export const updateRiskCredirLimitsMatrixAction = (matrix: RiskCreditLimitsMatrix): AsyncAction =>
  createAsyncAction(updateRiskCreditLimitsMatrixService, matrix);
