import { Button } from '@mui/material';
import { Box } from '@mui/system';
import deepEqual from 'fast-deep-equal';
import { toClassName } from 'helpers/toClassName';
import React, { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { updateRiskCredirLimitsMatrixAction } from 'redux/actions/riskActions';
import { AsyncAction } from 'redux/asyncAction';
import {
  restoreOriginalCreditLimitsMatrix,
  restoreOriginalCreditLimitsRows,
  riskSelector,
} from 'redux/reducers/riskReducer';
import { CreditLimitsTable } from 'routes/Risk/components/CreditLimitsTable';
import classes from 'routes/Risk/risk.module.scss';
import { RiskCreditLimitsMatrix } from 'types/risk';

interface Props {
  readonly riskCreditLimitsMatrix: RiskCreditLimitsMatrix | null;
}

export const CreditLimitsMatrix: React.FC<Props> = (props: Props): React.ReactElement => {
  const risk = useSelector(riskSelector);
  const dispatch = useDispatch<Dispatch<AsyncAction | AnyAction>>();

  const { riskCreditLimitsMatrix } = props;
  const { accounts } = riskCreditLimitsMatrix;

  const modified = React.useMemo(
    (): boolean => risk.riskCreditLimitsMatrix !== risk.originalRiskCreditLimitsMatrix,
    [risk.riskCreditLimitsMatrix, risk.originalRiskCreditLimitsMatrix]
  );

  const disableSubmit = React.useMemo((): boolean => {
    return (
      risk.savingRiskCreditLimitsMatrix ||
      deepEqual(risk.riskCreditLimitsMatrix, risk.originalRiskCreditLimitsMatrix)
    );
  }, [
    risk.riskCreditLimitsMatrix,
    risk.originalRiskCreditLimitsMatrix,
    risk.savingRiskCreditLimitsMatrix,
  ]);

  const handleReset = React.useCallback((): void => {
    dispatch(restoreOriginalCreditLimitsMatrix());
    dispatch(restoreOriginalCreditLimitsRows());
  }, [dispatch]);

  const handleSubmit = React.useCallback((): void => {
    dispatch(updateRiskCredirLimitsMatrixAction(risk.riskCreditLimitsMatrix));
  }, [dispatch, risk.riskCreditLimitsMatrix]);

  if (riskCreditLimitsMatrix === null) {
    return <div className={classes.matrix} />;
  } else {
    return (
      <div
        className={toClassName(classes.matrix, {
          [classes.savingMatrix]: risk.savingRiskMatrix,
        })}
      >
        <CreditLimitsTable items={accounts} label="" />
        <Box className={classes.buttonsContainer}>
          <Button onClick={handleReset} disabled={!modified}>
            Reset
          </Button>
          <Button variant="contained" onClick={handleSubmit} disabled={disableSubmit}>
            Submit
          </Button>
        </Box>
      </div>
    );
  }
};
