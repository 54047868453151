import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import config from 'config';
import equals from 'fast-deep-equal';
import React from 'react';
import { Store } from 'redux';
import { flowStatusChanged, sessionStatusChanged } from 'redux/reducers/flowsReducer';
import {
  SequenceUpdateDTO,
  sessionsUpdated,
  StatusUpdateDTO,
} from 'redux/reducers/sessionsReducer';
import { connected, updated } from 'redux/reducers/statusBarReducer';
import { isFlowMessage } from 'signalR/interfaces/flowMessage';
import { isSessionsMessage } from 'signalR/interfaces/sessionsMessage';

export class SignalR {
  private readonly connection: HubConnection;
  private readonly store: Store;
  private readonly eventHandlers: Record<string, (event: Event) => void>;

  constructor(url: string, store: Store) {
    this.eventHandlers = {};

    const connection = new HubConnectionBuilder()
      .withUrl(url, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .build();

    let lastMessage: any = null;

    connection.on('SM_CACHE_UPDATE', (data: any): void => {
      const message = JSON.parse(data);
      if (!equals(message, lastMessage)) {
        lastMessage = message;

        if (isSessionsMessage(message)) {
          store.dispatch(sessionsUpdated(message.Sessions));
          store.dispatch(sessionStatusChanged(message.Sessions));
        } else if (isFlowMessage(message)) {
          store.dispatch(flowStatusChanged(message.Flow));
        } else {
          console.warn('unknown message type', message);
        }

        store.dispatch(updated());
      }
    });
    this.connection = connection;
    this.store = store;
    // Start in background
    connection
      .start()
      .then((): Promise<void> => {
        this.onUpdateSequence();
        this.onUpdateStatus();
        this.onRiskUpdate(); // <-- Ensure risk updates are handled
        return connection.send('SM_CACHE_INIT', config.environment);
      })
      .then((): void => {
        store.dispatch(connected());
      })
      .catch(console.warn);
  }

  // Public methods
  public onConnected(fn: VoidFunction): VoidFunction {
    const connection = this.getConnection();
    connection.on('connected', fn);
    return (): void => {
      connection.off('connected', fn);
    };
  }

  // Risk Update methods
  private onRiskUpdate = (): void => {
    const { connection } = this;
    connection.on('onRiskUpdate', (jsonmsg: any): void => {
      const { account, consumed } = JSON.parse(jsonmsg);
      console.log('onRiskUpdate', account, consumed, jsonmsg);
      document.dispatchEvent(
        new CustomEvent<any>(`onRiskUpdate${account}`, {
          detail: { account, consumed },
        })
      );
    });
  };

  public subscribeForRiskUpdate(accountID: string, handler: (data: any) => void): void {
    const { connection } = this;

    if (connection === null) {
      throw new Error('Signal R is not connected yet');
    }

    const handlerWrapper = (event: Event): void => {
      const customEvent = <CustomEvent<any>>event;
      console.log('executing handlerWrapper');
      handler(customEvent.detail);
    };

    this.eventHandlers[`riskUpdate${accountID}`] = handlerWrapper;

    connection
      .send('SubscribeForRiskUpdate')
      .then((): void => {
        console.log(`🟢 Subscribed to risk updates for ${accountID}`);
        document.addEventListener(`onRiskUpdate${accountID}`, handlerWrapper);
      })
      .catch((error) => {
        console.error('❌ Failed to subscribe for risk updates:', error);
      });
  }

  public unsubscribeForRiskUpdate(accountID: string): void {
    const { connection } = this;

    if (connection === null) {
      throw new Error('Signal R is not connected yet');
    }

    const handler = this.eventHandlers[`riskUpdate${accountID}`];
    if (handler) {
      document.removeEventListener(`onRiskUpdate${accountID}`, handler);
    }

    connection.send('UnSubscribeForRiskUpdate').then((): void => {
      console.log(`🔴 Unsubscribed from risk updates for ${accountID}`);
      connection.off('onRiskUpdate');
    });
  }

  // ProtectedRoute methods
  private getConnection(): HubConnection {
    if (this.connection === null) {
      throw new Error('Signal R is not connected yet');
    }
    return this.connection;
  }

  private onUpdateSequence = (): void => {
    const { connection } = this;
    connection.on('onUpdateSequence', (message: any): void => {
      const { insequence, outsequence, sessionid } = JSON.parse(message);

      document.dispatchEvent(
        new CustomEvent<SequenceUpdateDTO>(`onUpdateSequence${sessionid}`, {
          detail: {
            inSequence: insequence,
            outSequence: outsequence,
            sessionID: sessionid,
          },
        })
      );
    });
  };

  private onUpdateStatus = (): void => {
    const { connection } = this;
    connection.on('onUpdateStatus', (message: any): void => {
      try {
        const { SequenceNum, Sessions } = JSON.parse(message);
        console.log(JSON.parse(message));
        if (!Array.isArray(Sessions)) {
          console.error('Invalid Sessions data format:', Sessions);
          return;
        }

        Sessions.forEach((session) => {
          const { SessionID, Status, StatusText, LastLogon } = session;
          console.log('onUpdateStatus', SequenceNum, SessionID, Status, StatusText, LastLogon);

          document.dispatchEvent(
            new CustomEvent<StatusUpdateDTO>(`onUpdateStatus${SessionID}`, {
              detail: {
                sequenceNum: SequenceNum,
                sessionID: SessionID,
                status: Status,
                statusText: StatusText,
                lastLogon: LastLogon,
              },
            })
          );
        });
      } catch (error) {
        console.error('Error parsing onUpdateStatus message:', error);
      }
    });
  };

  public subscribeForSequenceNumbers(
    sessionID: string,
    handler: (data: SequenceUpdateDTO) => void
  ): void {
    const { connection } = this;

    if (connection === null) {
      throw new Error('Signal R is not connected yet');
    }
    const handlerWrapper = (event: Event): void => {
      const customEvent = <CustomEvent<SequenceUpdateDTO>>event;
      handler(customEvent.detail);
    };
    this.eventHandlers[sessionID] = handlerWrapper;

    connection.send('SubscribeForSequence', sessionID).then((): void => {
      document.addEventListener(`onUpdateSequence${sessionID}`, handlerWrapper);
    });
  }

  public unsubscribeForSequenceNumbers(sessionID: string): void {
    const { connection } = this;
    if (connection === null) {
      throw new Error('Signal R is not connected yet');
    }
    const handler = this.eventHandlers[sessionID];
    if (handler) {
      document.removeEventListener(`onUpdateSequence${sessionID}`, handler);
    }

    connection.send('UnSubscribeForSequence', sessionID).then((): void => {
      connection.off('onUpdateSequence');
    });
  }

  public subscribeForStatusUpdates(
    sessionID: string,
    handler: (data: StatusUpdateDTO) => void
  ): void {
    const { connection } = this;

    if (connection === null) {
      throw new Error('Signal R is not connected yet');
    }

    const handlerWrapper = (event: Event): void => {
      const customEvent = <CustomEvent<StatusUpdateDTO>>event;
      handler(customEvent.detail);
    };
    this.eventHandlers[sessionID] = handlerWrapper;

    connection.send('SubscribeForSequence', sessionID).then((): void => {
      document.addEventListener(`onUpdateStatus${sessionID}`, handlerWrapper);
    });
  }

  public unsubscribeForStatusUpdates(sessionID: string): void {
    const { connection } = this;
    if (connection === null) {
      throw new Error('Signal R is not connected yet');
    }
    const handler = this.eventHandlers[sessionID];
    if (handler) {
      document.removeEventListener(`onUpdateStatus${sessionID}`, handler);
    }

    connection.send('UnSubscribeForSequence', sessionID).then((): void => {
      connection.off('onUpdateStatus');
    });
  }
}

export const SignalRContext = React.createContext<SignalR | undefined>(undefined);
