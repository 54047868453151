import { Dispatch } from '@reduxjs/toolkit';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { AsyncAction } from 'redux/asyncAction';
import {
  createAssetClassRequest,
  riskSelector,
  updateCreditLimitsRows,
  updateSymbolLimitsRows,
} from 'redux/reducers/riskReducer';
import { hasChildren } from 'redux/reducers/utils';
import classes from 'routes/Risk/risk.module.scss';
import { CreditLimitsTableRow, SymbolTableRow } from 'types/risk';

type HierarchicalAccount = {
  value: string;
  name: string;
  data: SymbolTableRow | CreditLimitsTableRow;
  children?: HierarchicalAccount[];
};

export const NestedRow: React.FC<{
  account: HierarchicalAccount;
  limitType: 'symbol' | 'credit';
}> = ({ account, limitType }) => {
  const dispatch = useDispatch<Dispatch<AsyncAction | AnyAction>>();
  const { symbolLimitsRows, creditLimitsRows } = useSelector(riskSelector);

  const hasAnyChildren: boolean =
    limitType === 'symbol' && 'assetClassType' in account.data
      ? hasChildren(
          symbolLimitsRows[(account.data as SymbolTableRow).assetClassType] as any,
          account.value
        )
      : hasChildren(creditLimitsRows as any, account.value);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (limitType === 'symbol' && 'assetClassType' in account.data) {
      dispatch(
        updateSymbolLimitsRows(
          createAssetClassRequest(account.data.assetClassType, {
            assetClassType: account.data.assetClassType,
            symbolLimits: { [account.value]: { id: account.value, data: account.data } },
          })
        )
      );
    } else {
      dispatch(
        updateCreditLimitsRows({
          [account.value]: { id: account.value, data: account.data as CreditLimitsTableRow },
        })
      );
    }
  };

  return (
    <>
      <div style={{ borderLeft: 'inherit' }}>
        <div className={classes.expandButton}>
          {account && account.data.children.length > 0 ? (
            <button onClick={handleClick} title="Expand">
              {hasAnyChildren ? (
                <i className="fa fa-chevron-down" />
              ) : (
                <i className="fa fa-chevron-right" />
              )}
            </button>
          ) : (
            <button disabled={true} />
          )}
          {account.value}
        </div>
      </div>
    </>
  );
};
